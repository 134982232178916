import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["search"];

  connect() {
    this.debouncedSearch = debounce(this.performSearch, 600);
  }

  search() {
    this.debouncedSearch();
  }

  performSearch() {
    const query = this.searchTarget.value;
    if (query.length >= 3) {
      Turbo.visit(`/checkins?query=${query}`, { action: "replace" });
    }
    this.searchTarget.focus(); // Refocus the input field
  }

  clear() {
    this.searchTarget.value = '';
    Turbo.visit(`/checkins`, { action: "replace" });
    this.searchTarget.focus(); // Refocus the input field
  }
}